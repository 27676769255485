<template>
	<div id="app">
		<test></test>
		<img src="./images/banner3@1x.png" style="width: 100%;" />
		<h1>供应链金融</h1>
		<div class="cont">
			<h3>供应链金融产品</h3>
			<img src="./images/zp_18.png" />
			<p>面向尚美集团上下游合作企业，基于供应链融资需求提供的经营性贷款产品。</p>
			<div class="scrool">
				<ul>
					<li v-for="(item,index) in list.slice(0,1)" :key="index" v-on:mouseover="changeActive(index,$event)" v-on:mouseout="removeActive(index)">
						<img src="./images/img.png" alt="">
						<div class="bg"></div>
						<div class="text" v-if="index==0" v-show="show">
							<h3>{{item.contentTitle}}</h3>
							<p>最高可借额度(元)</p>
							<h1>{{item.contentDescription}}</h1>
						</div>
						<div class="centers text" v-if="index==0" v-show="hide">
							<img :src="item.contentImg">
							<p>扫码了解更多</p>
						</div>
					
						<div class="txt">
							<h4>{{item.contentTitle}}</h4>
							<p  v-html='item.contentDetails'></p>
						<!-- 	<button v-if="index==0" v-show="show">在线申请</button>
							<button v-if="index==0" class="on" v-show="hide">在线申请</button> -->
						
						</div>
					</li>
					
				</ul>
			</div>			
			<h3 style="padding-top: 50px;">产品优势</h3>
			<img src="./images/zp_18.png" />
			<div class="ul">
				<div class="li">
					<img src="./images/icon_03.png" />
					<h3>安全保障</h3>
					<p>全流程风险管控<br>保障企业信息和资金安全</p>
				</div>
				<div class="li">
					<img src="./images/icon_05.png" />
					<h3>息费透明</h3>
					<p>根据风险匹配度匹配利率<br>借款费用及时报告，无隐形费用</p>
				</div>
				<div class="li">
					<img src="./images/icon_07.png" />
					<h3>周转灵活</h3>
					<p>灵活还款周期选择<br>按月还款、一次性还本多种选择</p>
				</div>
				<div class="li">
					<img src="./images/icon_09.png" />
					<h3>赋能场景</h3>
					<p>深挖场景供应链特征<br>赋能产业发展</p>
				</div>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
import test from './components/test.vue'
import foot from './components/foot.vue'
export default {
	
	components: {
		test,
		foot	
	},
	data() {
		return {
		  inActive: false,
		  list:[],
		  show:true,
		  hide:false,
		
		 
		}
  },
  created(){
	if (location.href.indexOf("#reloaded") == -1) {
		  location.href = location.href + "#reloaded";
		  location.reload();
	}
	var that = this
	this.$axios.post('/cms/returnAll/officialWebsite')
	.then(function (res) {		
		console.log(res);
		that.list = res.data.categoryList[0].contentList
	})
  
  },
  methods: {
	  // build(){
		 //  var that = this;
		 //  that.$router.push('/build');
	  // },
	 changeActive(index,$event){
		 console.log($event)
		 if(index==0){
			 this.hide = true
			 this.show = false
		 }
		 if(index==1){
			 this.hide2 = true
			 this.show2 = false
		 }
		 if(index==2){
			 this.hide3 = true
			 this.show3 = false
		 }
		 
	     // $event.currentTarget.className="on";
	  },
	  removeActive(index){
		  if(index==0){
			  this.hide = false
			  this.show = true
		  }else if(index==1){
			  this.hide2 = false
			  this.show2 = true
		  }else if(index==2){
			  this.hide3 = false
			  this.show3 = true
		  } 
	  }
	  
  },


}
</script>

<style>
	/* .cont ul li:first-child{
		margin-left: 17%;
	} */
	button{
		outline: none;
	}
	.cont ul li .txt .on{
		background-color: #ea0029;
		color: #fff;
	}
	#app>h1{
		font-size: 40px;
		letter-spacing:5px;
		font-weight: 300;
		position: absolute;
		top:30%;
		left: 50%;
		transform: translateX(-50%);
		color: #fff;
	}
	.scrool{
		/* width: 1200px; */
		/* overflow:scroll; */
	}
	.ul{
		display: flex;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 80px;
	}
	.ul .li{
		flex: 2;
		margin: 2%;
		
	}
	.ul .li h3{
		color: #3B3B3B;
	}
	.ul .li img{
		width: 50%;
		margin-bottom: 10px;
	}
	.cont>img{
		width: 50px;
	}
	.cont ul li .txt{	
		margin-top: 350px;
		text-align: left;
		/* height: 130px; */
		border: 1px solid #f4f6f8;
		padding: 4%;			
	}
	.cont ul li .txt button{
		margin-top: 20px;
		font-size: 14px;
		border: 1px solid #ea0029;
		background-color: #fff;
		border-radius: 30px;
		color: #ea0029;
		padding: 13px 25px;
		position: absolute;
		bottom: 20px;
		width: 91%;
	}
	.cont ul li .txt h4{
		font-weight: normal;
		font-size: 18px;
		color: #3B3B3B;
	}
	.cont ul li .centers{	
		text-align: center !important;
		width: 86%;
	}
	.cont ul li .centers img{
		width: 80px;
		height: auto;
	}
	.cont ul li .text{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		color: #fff;
		padding: 7%;
		/* text-align: left; */
	}
	.cont ul li .text p{
		color: #fff;
	}
	.cont ul li .text h3{
		font-weight: normal;
		color: #fff;
	}
	.cont ul li .text h1{
		margin-top: 10px;
		font-size: 20px;
	}
	.cont{
		overflow: hidden;
		width: 65%;
		margin: 0 auto;
	}
	.cont h3{
		margin: 0;
		color: #333;
		padding-top: 30px;
	}
	.cont p{
		font-size: 12px;
		color: #666;
		margin-top: 20px;
	}
	.cont ul{
		margin-bottom: 60px;
		margin-top: 30px;
		display: flex;
		justify-content: center;
	}
	.cont ul li{
		position: relative;
		width: 75%;
		margin: 1%;
	}
	.cont ul li .centers>img{
		width: 150px;
		height: auto;
	}
	.cont ul li>img{
		width: 100%;
		height: 350px;
		position: absolute;
		top: 0;
		left: 0;
	}
	.cont ul li .bg{
		width:100%;
		height: 350px;
		position: absolute;
		top: 0;
		left: 0;
		/* z-index: 9; */
	}
	.cont ul li:first-child .bg{
		background:rgba(234,0, 41,0.5);
	}
	.cont ul li:nth-child(2) .bg{
		background:rgba(1,39,108,0.8);
	}
	.cont ul li:nth-child(3) .bg{
		background:rgba(138,53,6,0.5);
	}
</style>
